import { VIDEO_ARGS_SEPARATOR } from '~/plugins/vue-sanitize';

const VIDEO_SOURCES_MAP = {
  youtube: { src: id => `https://www.youtube.com/embed/${id}?showinfo=0` },
  vimeo: { src: id => `https://player.vimeo.com/video/${id}` },
  loom: { src: id => `https://www.loom.com/embed/${id}` },
  pandaVideo: {
    src: (id) => {
      const [customerZone, videoId] = id.split(VIDEO_ARGS_SEPARATOR);

      return `https://player-vz-${customerZone}.tv.pandavideo.com.br/embed/?v=${videoId}`;
    },
  },
};

const videoTag = ([_, source, id]) => `<iframe class="ql-video" frameborder="0" allowfullscreen="true" src="${VIDEO_SOURCES_MAP[source].src(id)}"></iframe>`;

export const parseEmbeddedVideo = (html) => {
  if (!html) return html;

  const allowedSources = Object.keys(VIDEO_SOURCES_MAP);

  let parsed = html;
  const regex = new RegExp(`!!\\[(${allowedSources.join('|')})\\]\\(([a-zA-Z0-9_-]+|\\d+)\\)`, 'g');
  const localReplaceRegex = new RegExp(`!!\\[(${allowedSources.join('|')})\\]\\(([a-zA-Z0-9_-]+|\\d+)\\)`);

  const matches = [...parsed.matchAll(regex)];

  matches.forEach((match) => {
    if (!allowedSources.includes(match[1])) return;

    parsed = parsed.replace(
      localReplaceRegex,
      videoTag(match),
    );
  });

  return parsed;
};
