import type { NuxtApp } from '#app';

const PATHS = {
  uploadImage: '/builder/content_images/',
  uploadMarkdownFieldImage: '/markdown_field_images/',
};

export class FileUploader {
  $api: NuxtApp['$api'];

  $externalApi: NuxtApp['$externalApi'];

  constructor(api: NuxtApp['$api'], externalApi: NuxtApp['$externalApi']) {
    this.$api = api;
    this.$externalApi = externalApi;
  }

  async createImageFile(file: File, path: string, baseParams: Record<string, unknown> = {}) {
    const params = {
      ...baseParams,
      filename: file.name,
      mime_type: file.type,
    };

    return this.$api.$post(path, { body: params });
  }

  async uploadFileToS3(uploadUrl: string, file: File) {
    const headers = { 'Content-Type': 'application/octet-stream' };
    await this.$externalApi.$put(uploadUrl, { body: file, headers });
  }

  async makeImageAvailable(id: string, path: string, size: number) {
    return this.$api.$patch(`${path}${id}`, { body: { available: true, size } });
  }

  async uploadImageFile(file: File, imageType: 'content' | 'markdown', baseParams: Record<string, unknown> = {}) {
    const path = imageType === 'content' ? PATHS.uploadImage : PATHS.uploadMarkdownFieldImage;
    const { id, upload_url: uploadUrl } = await this.createImageFile(file, path, baseParams);
    await this.uploadFileToS3(uploadUrl, file);

    return this.makeImageAvailable(id, path, file.size);
  }
}
